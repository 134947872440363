/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import './src/styles/_reset.scss'
import './src/styles/_global.scss'
import '@fontsource/ubuntu/500.css'
import '@fontsource/noto-sans-jp/400.css'
import '@fontsource/noto-sans-jp/700.css'

export const onInitialClientRender = () => {
  let vw;
  const target = document.querySelector("body");
  target.classList.add("loaded");
  vw = document.documentElement.clientWidth;
  document.documentElement.style.setProperty('--vw', vw + 'px');

  window.onresize = function () {
    vw = document.documentElement.clientWidth;
    document.documentElement.style.setProperty('--vw', vw + 'px');
  }
}
